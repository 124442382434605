// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import AnonymousDashboard from "./dashboards/anonymous";
import CustomerDashboard from "./dashboards/customer";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import {PAGE_HOME} from "../../locales/pages/namespaces";
import PartnerDashboard from "./dashboards/partner";
import AdminDashboard from "./dashboards/admin";
import useSyncLeads from "../../hooks/syncData/useSyncLeads";
import ContentLoader from "../../components/contentLoader/contentLoader";
import {blueGrey} from "@mui/material/colors";

const Home = () => {
	const {t} = useTranslation(PAGE_HOME)
	const {loading} = useSyncLeads()
	return !loading ? (
		<Box>
			<PermissionHOC requiredPermissions={['access_cust_dashboard','access_anonymous_dashboard']}>
				<CustomerDashboard/>
			</PermissionHOC>
			{/*<PermissionHOC requiredPermissions={['access_anonymous_dashboard']}>
				<AnonymousDashboard/>
			</PermissionHOC>*/}
			<PermissionHOC requiredPermissions={['access_partner_dashboard']}>
				<PartnerDashboard/>
			</PermissionHOC>
			<PermissionHOC requiredPermissions={['access_admin_dashboard']}>
				<AdminDashboard/>
			</PermissionHOC>
		</Box>
	) : (
		<ContentLoader
			withBackDrop={true}
			useCircularLoader={true}
			loading={loading}
			color={blueGrey[50]}
			message={t("Synchronization is in progress. Please wait.")}
		/>
	)
}

export default Home
