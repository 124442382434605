import React from "react";

import SuccessMessage from "../../elements/SuccessMessage";
import WarningMessage from "../../elements/WarningMessage";
import ErrorMessage from "../../elements/ErrorMessage";
import { useWizard } from "../WizardContext";


const ResultMessage = () => {
  const { responses, summary } = useWizard();

  const prefix =
    summary.max_amount < responses["10"].AnswerDescription
      ? "The loan amount resulting"
      : "The loan amount you have requested";

  const LEVELS = {
    "0": <ErrorMessage message="Unfortunately, you cannot receive pre-approval for a mortgage loan" />,
    "1": <ErrorMessage message="The loan amount you have requested is marginally viable, and the payment of the installment will put pressure on your family income" />,
    "2": <WarningMessage message={`${prefix} is viable, but the payment of the installment will put some pressure on your family income`} />,
    "3": <SuccessMessage message="The loan amount you have applied for is sustainable, and the installment payment will be made without significant pressure on your family income" />,
  }

  return LEVELS[summary.approval_level];
}

export default ResultMessage;
