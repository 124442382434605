import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.LOANS]: {
		el: {
			'Loan Calculator': 'Υπολογισμός Δανείου',
			'Loan Amount (€)': 'Το ποσό που θέλω να δανειστώ είναι (€)',
			'Interest Rate': 'Ονομαστικό Επιτόκιο %',
			'Repayment In Years': 'Αποπληρωμή σε έτη',
			'Final Interest (%)': 'Τελικό Επιτόκιο (%)',
			'Total Repayment Amount (€)': 'Συνολικό ποσό αποπληρωμής (€)',
			'Register to save your information': 'Εγγραφείτε για να αποθηκεύσετε τον υπολογισμό',
			'Loans': 'Δάνεια',
			'Mortgages loans': 'Στεγαστικά δάνεια',
			'Code': 'Κωδικός',
			'Add': 'Προσθήκη',
			'Description': 'Περιγραφή',
			'File Name': 'Όνομα εγγράφου',
			'Loan Duration In Years': 'Διάρκεια Δανείου',
			'Ιnterest': 'Επιτόκιο',
			'Start Date': 'Ημερομηνία Έναρξης Δανείου',
			'Monthly Allowance': 'Μηνιαία Δόση',
			'Loan purpose': 'Σκοπός Δανείου',
			'How did you learn about us': 'Πως μας βρήκατε;',
			'I already found a property': 'Με Ακίνητο',
			'File size exceeds 10MB.': 'Το μέγεθος του αρχείου υπερβαίνει τα 10MB.',
			'File size exceeds 50MB.': 'Το μέγεθος του αρχείου υπερβαίνει τα 50MB.',
			'The file must be in PDF format.': 'Το αρχείο πρέπει να είναι σε μορφή PDF.',
			'Invalid file type. Please upload a PDF, JPG, JPEG, PNG, ZIP, or 7Z file.': 'Μη έγκυρος τύπος αρχείου. Ανεβάστε ένα αρχείο PDF, JPG, JPEG, PNG, ZIP ή 7Z.',
			'Invalid file type.': 'Μη έγκυρος τύπος αρχείου.',
			'Create Loan': 'Δημιουργία Δανείου',
			'Submit': 'Καταχώριση',
			'Close': 'Ακύρωση',
			'Property Commercial Value': 'Εμπορική Αξία Ακινήτου',
			'Save': 'Αποθήκευση',
			'Loan Request': 'Αίτημα στεγαστικού δανείου',
			'Edit Loan Request': 'Επεξεργασία Αιτήματος Δανείου',
			'Create Request': 'Δημιουργία Αιτήματος',
			'Edit Request': 'Επεξεργασία Αιτήματος',
			'Home Page': 'Αρχική Σελίδα',
			'Loan Details': 'Λεπτομέρειες Δανείου',
			'Name': 'Επωνυμία',
			'Phone': 'Τηλέφωνο Επικοινωνίας',
			'Requested Amount': 'Ποσό Δανείου',
			'Real Estate Value': 'Εμπορική Αξία Ακινήτου',
			'Property Value': 'Περιοχή Ακινήτου',
			'Loan Purpose': 'Σκοπός Δανείου',
			'Loan Origin': 'Προέλευση',
			'Recommended by': 'Συστήθηκε από',
			'I agree with the Terms and Policies': 'Αποδέχομαι την δήλωση απορρήτου',
			'I agree with the': 'Αποδέχομαι την',
			'Terms and Policies': 'δήλωση απορρήτου',
			'Type': 'Τύπος',
			'I am looking for property': 'Αναζητώ Ακίνητο',
			'Comments': 'Σχόλια',
			'Actions': 'Επιλογές',
			'Details': 'Λεπτομέρειες',
			'Edit': 'Επεξεργασια',
			'Documents': 'Έγγραφα',
			'Upload document': 'Μεταφόρτωση Αρχείου',
			'Updates': 'Ενημερώσεις',
			'documents': 'Έγγραφα/ Δικαιολογητικά',
			'Property Commercial Value (€)': 'Εμπορική αξία ακινήτου (€)',
			'Loan amount (€)': 'Ποσό δανείου (€)',
			'Calculation': 'Υπολογισμός',
			'Created': 'Δημιουργήθηκε',
			'Loan status': 'Κατάσταση Δανείου',
			'Required': 'Υποχρεωτικό',
			"Value must be a positive number.": "Η τιμή πρέπει να είναι θετικός αριθμός.",
			"Accepting the privacy statement is mandatory to proceed.": "Η αποδοχή της δήλωσης απορρήτου είναι υποχρεωτική για να προχωρήσετε.",
			"Loans Information": "Πληροφορίες Δανείων",
			"Property Questionnaire": "Αναζήτηση ακινήτου",
			'Failed to get loan details.': 'Αδυναμία φόρτωσης λεπτομερειών δανείου.',
			'Loan Updated Successfully!': 'Επιτυχής ενημέρωση αίτησης δανείου!',
			'Loan Stored Successfully!': 'Επιτυχής καταχώριση αίτησης δανείου!',
			'Cancel': 'Ακύρωση',
			'Failed to get loan calculator.': 'Αδυναμία φόρτωσης αριθμομηχανής δανείου.',
			'There are currently no documents to upload.': 'Δεν υπάρχουν προς το παρόν έγγραφα που πρέπει να ανεβάσετε.',
			'No loan request has been registered yet.': 'Δεν έχει καταχωριθεί ακόμα κάποια αίτηση δανείου.',
			'Loan amount must be less than 100,000,000.': 'Το ποσό δανείου πρέπει να είναι μικρότερο από 100.000.000',
			'Real estate value must be less than 100,000,000.': 'Η εμπορική αξία ακινήτου πρέπει να είναι μικρότερη από 100.000.000',
			'The value is too big.': 'Η τιμή είναι πολύ μεγάλη.',
			'Information': 'Πληροφορίες',
			'Search...': 'Αναζήτηση...',
			'Select all': 'Επιλογή όλων',
			'Enter loan amount': 'Εισαγάγετε ποσό δανείου',
			'Enter property commercial value': 'Εισαγάγετε εμπορική αξία ακινήτου',
			'Enter partner name': 'Εισαγάγετε όνομα συνεργάτη',
			'View': 'Προβολή',
			'Sent': 'Στάλθηκε',
			"Question from IMS": "Ερώτημα από IMS",
			"Change loan application status": "Αλλαγή κατάστασης δανείου",
			"Questionnaires": "Ερωτηματολόγια",
			'Update for pending': 'Ενημέρωση για εκκρεμότητα',
			'I consent to give my personal data to third party real estate agencies for property finding based on the provided personal preferences.': 'Συναινώ στην διαβίβαση των προσωπικών μου δεδομένων στις συνεργαζόμενες εταιρίες μεσιτείας ακινήτων προς ενημέρωσή μου για ανεύρεση ακινήτου',
			"Loading... Please don't close the browser before the process ends.": "Φόρτωση... Μην κλείσετε το πρόγραμμα περιήγησης πριν τελειώσει η διαδικασία.",
			'Uploading...': 'Μεταφόρτωση...',
			"Bank": 'Τράπεζα',
			'Approved amount (€)': 'Εγκεκριμένο ποσό (€)',
			'Approved amount': 'Εγκεκριμένο ποσό',
			'There are no updates.': 'Δεν υπάρχουν ενημερώσεις.'
		},
		en: {
			'documents': 'Documents'
		}
	},
}

export default locales