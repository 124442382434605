import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { useWizard } from "../WizardContext";
import { WIZARD } from "../../../locales/namespaces";

import ResultMessage from "./ResultMessage";
import LoanSummary from "./LoanSummary";
import MonthlyAmount from "./MonthlyAmount";
import MaxAmount from "./MaxAmount";
import CtaMessage from "./CtaMessage";
import CtaForm from "./CtaForm";
import { Box } from "@mui/material";

const Summary = () => {
  const { t } = useTranslation(WIZARD);
  const { resetSummary, responses, summary } = useWizard();

  const isMaxAmountLarger = summary.max_amount > responses["10"].AnswerDescription;

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={3}>
          <ResultMessage />
          <LoanSummary />
          {isMaxAmountLarger && <MonthlyAmount />}
          <MaxAmount />
          {!isMaxAmountLarger && <MonthlyAmount />}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            p: 4,
            borderRadius: "8px",
            backgroundColor: "#ffffff",
          }}
        >
          <Box sx={{ mb: 7 }} >
            <CtaMessage />
          </Box>
          <CtaForm />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="info"
          sx={{
            fontSize: "13px",
            "&:hover": {
              borderWidth: "1px",
              backgroundColor: "#0BC1B61A",
            }
          }}
          onClick={resetSummary}
        >
          {t("Previous")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Summary;
